.modal-container{
    cursor: not-allowed;
    background-color: #03040B;
    color: white;
    padding: 25px 15px;
    border-radius: 24px;
    font-size: 1.2rem;
    line-height: 175%;
    box-shadow: 6px 6px 10px #03040B;
}

.button{
    cursor: pointer;
}

.highlighted{
    color: rgb(0, 221, 111);
}

.copy_email_button{
    border:0;
    margin: 4px 0;
    padding: 3px 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.copy_email_button:hover{
    fill:rgb(0, 221, 111);
}

.copy_email_parent{
    background: transparent;
    border: none;
    color: white;
}