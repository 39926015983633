@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

* {
  font-family: 'Roboto Mono', monospace;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body{
  scroll-behavior: smooth;
  scrollbar-color: #01bf71 grey;
  scrollbar-width: thin;
  overflow-x: hidden;
  position: relative;
}

#home{
  background-color: rgb(8, 12, 31);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #00dd6f !important;
}

.Toastify__toast-body {
  color:rgb(8, 12, 31);
}